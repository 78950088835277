@font-face {
  font-family: "Union";
  src: url("./fonts/union.eot");
  src: url("./fonts/union.eot?#iefix") format("embedded-opentype"),
    url("./fonts/union.woff2") format("woff2"),
    url("./fonts/union.woff") format("woff"),
    url("./fonts/union.ttf") format("truetype"),
    url("./fonts/union.svg#union") format("svg");
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'Union', Helvetica, Arial, sans-serif;
  background-color: black;
  color: aliceblue;
  -webkit-font-smoothing: antialiased;
  font-size: max(6vw, 36px);
}

.Root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.Play,
.Word,
.Error {
  text-transform: uppercase;
  letter-spacing: 0.0625em;
}

.Play {
  appearance: none;
  padding: 0;
  border: 0;
  margin: 0;
  background-color: transparent;
  color: inherit;
  font: inherit;
  text-align: inherit;
  text-align: center;
  cursor: pointer;
  transition: color 250ms;
  line-height: 1;
  padding: 0.25em 0.5em;

  &:hover {
    color: blue;
  }
}

.Word {
  transition: color 250ms, opacity 500ms, transform 1s;
  user-select: none;

  &--active {
    color: blue;
    opacity: 0;
    transform: scale(0.9)
  }
}

.Error {
  color: red;
}