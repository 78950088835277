@font-face {
  font-family: Union;
  src: url("union.0cb4d2a3.eot");
  src: url("union.0cb4d2a3.eot#iefix") format("embedded-opentype"), url("union.0a10dfed.woff2") format("woff2"), url("union.1c41826c.woff") format("woff"), url("union.42560a30.ttf") format("truetype"), url("union.0106535a.svg#union") format("svg");
  font-weight: normal;
  font-style: normal;
}

body {
  color: #f0f8ff;
  -webkit-font-smoothing: antialiased;
  background-color: #000;
  font-family: Union, Helvetica, Arial, sans-serif;
  font-size: max(6vw, 36px);
}

.Root {
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.Play, .Word, .Error {
  text-transform: uppercase;
  letter-spacing: .0625em;
}

.Play {
  appearance: none;
  color: inherit;
  font: inherit;
  text-align: inherit;
  text-align: center;
  cursor: pointer;
  background-color: #0000;
  border: 0;
  margin: 0;
  padding: .25em .5em;
  line-height: 1;
  transition: color .25s;
}

.Play:hover {
  color: #00f;
}

.Word {
  user-select: none;
  transition: color .25s, opacity .5s, transform 1s;
}

.Word--active {
  color: #00f;
  opacity: 0;
  transform: scale(.9);
}

.Error {
  color: red;
}

/*# sourceMappingURL=index.8808c062.css.map */
